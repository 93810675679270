<template>
  <div class="app-home">
    <div class="app-home-head">
      Tps Import
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div class="app-home-table">
      <div class="table-name">
        <span>Tps List</span>
        <el-button type="primary" @click="handleClick">
          <template>
            <i class="el-icon-plus"></i>
            <span>New</span>
          </template>
        </el-button>
      </div>

      <el-row style="padding: 10px">
        <el-col :span="9">
          <span>Product Name：</span>
          <!-- <el-input v-model="searchData.name" style="width:350px;"></el-input> -->
          <el-autocomplete
            clearable
            v-model="searchData.name"
            :fetch-suggestions="nameSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectName"
          ></el-autocomplete>
        </el-col>
        <el-col :span="9">
          <div>
            <span>Item Code：</span>
            <!-- <el-input v-model="searchData.code" style="width:350px;"></el-input> -->
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="searchData.code"
              :fetch-suggestions="codeSearch"
              placeholder="Please Input"
              @input="handleSelectCode"
            ></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-button @click="handleSearch('查询')" type="primary"
              >Search</el-button
            >
            <el-button @click="handleSearch('重置')">Reset</el-button>
          </div>
        </el-col>
      </el-row>

      <div
        :class="[{ 'table-list': true }, screen_ratio]"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="Desperately loading..."
        style="height: 77vh"
      >
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
        >
          <el-table-column
            type="index"
            label="Number"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="productName"
            label="Product Name"
          ></el-table-column>

          <el-table-column prop="itemCode" label="Item Code">
            <template slot="header">
              <span>Item Code</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': itemCodeSort,
                }"
                @click="handleSort('itemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <span :style="{ color: isRepeat(scope.row.itemCode) }">{{
                scope.row.itemCode
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="productDescription"
            label="Product Description"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="SupplierLotBatchNo"
            label="Supplier Lot/Batch No."
            width="300"
          ></el-table-column>
          <!-- <el-table-column prop="generalSpecificatio" label="General Specification"></el-table-column> -->
          <el-table-column label="Verify" width="100">
            <template slot-scope="scope">
              <i
                :class="verify_show(scope.row).icon"
                :style="{ color: verify_show(scope.row).color }"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            prop="createName"
            label="Create Name"
          ></el-table-column>
          <el-table-column prop="editName" label="Edit Name"></el-table-column>
          <el-table-column
            prop="verifiedName"
            label="Verified Name"
          ></el-table-column>

          <el-table-column
            prop="verifiedTime"
            label="Verified Time"
            width="140"
          >
            <template slot="header">
              <span>Verified Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': verifiedTimeSort,
                }"
                @click="handleSort('verifiedTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.verifiedTime) }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="createTime" label="Create Time" width="140">
            <template slot="header">
              <span>Create Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': createTimeSort,
                }"
                @click="handleSort('createTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="updateTime" label="Edit Time" width="140">
            <template slot="header">
              <span>Edit Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': updateTimeSort,
                }"
                @click="handleSort('editTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.updateTime) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Action" width="180px">
            <template slot-scope="scope">
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">Edit</span>
              <span
                v-if="
                  username === 'JamesChen' ||
                  username === 'admin' ||
                  username === 'JiaZhang'
                "
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="handleClick(scope.row, '删除')"
                >Delete</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="table-pagition">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageIndex"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";

export default {
  name: "home",
  mixins: [base],
  data() {
    return {
      loading: false,
      tableData: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        itemCode: "",
        productName: "",
      },
      searchData: {
        name: "",
        code: "",
      },
      preview: {},
      createTimeSort: true,
      updateTimeSort: true,
      verifiedTimeSort: true,
      itemCodeSort: true,
      screen_ratio: "",
      nameList: [],
      codeList: [],
      getSearchList: true,
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      localStorage.setItem("loginUserNameKey", userInfo.username);
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    handleSelectName() {
      this.handleSearch("查询");
    },
    handleSelectCode() {
      this.handleSearch("查询");
    },
    nameSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeTpsListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.productName });
      });
      // var restaurants = this.nameList;
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    codeSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeTpsListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    isRepeat(val) {
      let isRepeat = 0;
      this.tableData.forEach((v) => {
        if (val == v.itemCode) {
          isRepeat++;
        }
      });
      if (isRepeat > 1) {
        return "black";
      } else {
        return "black";
      }
    },
    getTime(time) {
      if (time) {
        return this.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    logout() {
      let token = localStorage.getItem("specToken");
      let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
      this.$api
        .logout({ id, token })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$message({ type: "success", message: "Logout SuccessFully" });
          localStorage.clear("specToken");
          localStorage.clear("specUserInfo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm() {
      this.$confirm("Are you sure to quit?", "Tips", {
        confirmButtonText: "Confrim",
        cancelButtonText: "Cancel",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getData(this.params);
    },
    handleClick(val, type) {
      switch (type) {
        case "删除":
          {
            this.$confirm(
              "This will permanently delete the data. Continue?",
              "Tips",
              {
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
                distinguishCancelAndClose: true,
                type: "warning",
              }
            ).then(() => {
              this.deleteItem(val);
            });
          }
          break;
        case undefined:
        case "编辑":
          {
            let data = JSON.stringify(val.rowData);
            localStorage.setItem("tpsDataKey", data);
            this.$router.push({
              path: "new-item",
              query: { id: val.id, type: "TPS" },
            });
          }
          break;
        default:
          break;
      }
    },
    deleteItem(val) {
      this.$api.handleTps
        .deleteTpsItem(val.id)
        .then((data) => {
          console.log(data);
          this.getData(this.params);
          this.$message({ type: "success", message: "Delete SuccessFully" });
        })
        .catch((err) => {
          console.log(err);
      });
    },
    getData(params) {
      this.loading = true;
      this.getOtherData(params);
    },
    getOtherData(params) {
      console.log("getOtherTable");
      this.$api.handleTps
        .getOtherTable(params)
        .then((data) => {
          let listData = [];
           
          data.records.forEach((element) => {
            
            //let jsonValueStr = element.jsonValue.replaceAll("&quot;", '"');
            let jsonValueStr =element.jsonValue.replace(new RegExp('&quot;', 'gm'), '"');
            console.log(jsonValueStr);
            let jsonValue = JSON.parse(jsonValueStr);
            let item = {
              id: element.id,
              createName: element.updateAuth,
              updateName: element.updateAuth,
              productName: jsonValue.DracoProductName,
              SupplierLotBatchNo: jsonValue.SupplierLotBatchNo,
              itemCode: jsonValue.ItemCode,
              createTime: element.updateTime,
              editTime: element.updateTime,
              rowData: jsonValue,
            };
            listData.push(item);
          });
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = listData;
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSearch(type) {
      switch (type) {
        case "重置":
          {
            this.searchData = { name: "", code: "" };
          }
          break;
        case "查询":
          {
            let { name, code } = this.searchData;
            this.params.itemCode = code;
            this.params.productName = name;
            this.params.pageIndex = 1;

            // localStorage.setItem('searchParamKey',JSON.stringify({name,code}))
            this.getData(this.params);
          }
          break;
        default:
          break;
      }
    },
    getPreview(params) {
      this.$api
        .getPreview(params)
        .then((data) => {
          let obj = {
            NewestTime: "",
            NewestCode: "",
            spenTotal: "",
          };
          this.preview = data || obj;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    verify_show(val) {
      switch (val.verified) {
        case "true":
          return { icon: "el-icon-success", color: "#67c23a" };
        case "false":
          return { icon: "el-icon-error", color: "#f56c6c" };
        default:
          return { icon: "el-icon-question", color: "#a6a9ad" };
      }
    },
    handleSort(val) {
      switch (val) {
        case "createTime":
          {
            this.createTimeSort = !this.createTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.createTime).getTime();
              let bTime = new Date(b.createTime).getTime();
              return this.createTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "editTime":
          {
            this.updateTimeSort = !this.updateTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.updateTime).getTime();
              let bTime = new Date(b.updateTime).getTime();
              return this.updateTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "verifiedTime":
          {
            this.verifiedTimeSort = !this.verifiedTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.verifiedTime).getTime();
              let bTime = new Date(b.verifiedTime).getTime();
              return this.verifiedTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "itemCode":
          {
            this.itemCodeSort = !this.itemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.itemCode;
              let bitemCode = b.itemCode;
              return this.itemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        default:
          break;
      }
    },
    getTpsItemCodeListUrl(params) {
      this.$api.handleTps
        .getTpsItemCodeListUrl(params)
        .then((data) => {
          console.log("itemCodeTpsListKey")
          console.log(data)
          localStorage.setItem("itemCodeTpsListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleCoaClick(){
      this.$router.push({ path: "coa-items"});
    }
  },
  mounted() {
    this.getTpsItemCodeListUrl({});
  },
  created() {
    this.getData(this.params);
    // this.getPreview();
    let sheight = window.screen.height;
    let swidth = window.screen.width;
    let ratio = swidth / sheight;
    switch (ratio) {
      case 16 / 9:
        this.screen_ratio = "ratio-16-9";
        break;
      case 16 / 10:
        this.screen_ratio = "ratio-16-10";
        break;
      case 3 / 2:
        this.screen_ratio = "ratio-3-2";
        break;
      case 4 / 3:
        this.screen_ratio = "ratio-4-3";
        break;
      default:
        this.screen_ratio = "ratio-16-10";
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>